export const api_routes = {
  get_user_url: '/website/profile',
  get_translation_url: '/website/translations',
  get_subjects_url: '/website/college/subjects',
  register_url: '/website/college/register',
  get_course_messages_url: '/website/college/course/messages',
  get_unread_messages_url: '/website/college/messages/unread',
  get_courses_url: '/website/college/courses/search',
  get_courses_url_auth: '/auth/website/college/courses/search',
  get_college_url: '/website/college',
  login_url: '/website/college/login',
  forgot_url: '/website/auth/forgot',
  reset_url: '/website/auth/reset',
  get_article_url: '/website/article',
  get_articles_url: '/website/articles',
  get_teacher_url: '/website/college/teacher',
  get_teachers_url: '/website/college/teachers',
  send_message_to_teacher_url: '/website/teacher/message',
  send_call_request_url: '/website/call-request',
};
